<div class="">
  <div class="tw-relative tw-w-full tw-bg-gradient-to-r tw-from-apicuron-purple tw-to-apicuron-red tw-text-gray-200">
    <div class="tw-flex tw-flex-row tw-flex-wrap tw-w-full tw-justify-between sm:tw-justify-around tw-container sm:tw-p-6 tw-p-2 tw-mx-auto">
      <div class="tw-p-1 sm:tw-p-2">
        <img src="assets/APICURON_white.svg" alt="APICURON LOGO">
      </div>

      <div class="tw-p-1 sm:tw-p-2">
        <span class="tw-text-apicuron-purple-100 tw-opacity-75 tw-font-bold tw-text-lg sm:tw-mb-2">
          About us </span>
        <ul class="tw-text-sm tw-font-normal">
          <li>
            <a [routerLink]="'/about'" fragment="intro">About APICURON </a>
          </li>
          <li>
            <svg-icon src="assets/svg/member.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a [routerLink]="['/about']" fragment="becomemember">Become a member Resource</a>
          </li>
          <li>
            <svg-icon src="assets/svg/tags.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a [routerLink]="['/about']" fragment="license">License</a>
          </li>
          <li>
            <svg-icon src="assets/svg/sitemap.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a [routerLink]="['/about']" fragment="governance">Governance</a>
          </li>
          <li>
            <svg-icon src="assets/svg/euro.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a [routerLink]="['/about']" fragment="funding">Funding</a>
          </li>
          <li>
            <svg-icon src="assets/svg/box.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>

            <a [routerLink]="['/about']" fragment="qa-life-cycle-plan">QA Plan</a>
          </li>
          <li>
            <svg-icon src="assets/svg/badge-solid.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a [routerLink]="['/about']" fragment="sab">SAB</a>
          </li>
        </ul>
      </div>
      <div class="tw-p-1 sm:tw-p-2 tw-order-last sm:tw-order-none">
        <span class="tw-text-apicuron-purple-100 tw-opacity-75 tw-font-bold tw-text-lg sm:tw-mb-2">
          Help
        </span>
        <ul class="tw-text-sm tw-font-normal">
          <li>
            <a routerLink="/help">Help</a>
          </li>
          <li>

            <a [href]="host + '/docs/'">
              <svg-icon src="assets/svg/document-text.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
              Documentation
            </a>
          </li>
          <li>
            <svg-icon src="assets/svg/wrench.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a [href]="ws">API Documentation</a>
          </li>
          <li>
            <svg-icon src="assets/svg/widget.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a [href]="host + '/docs/dev/widgets.html'">APICURON Widgets</a>
          </li>
          <li>
            <svg-icon src="assets/svg/message-bubble.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a [routerLink]="['/help']" fragment="feedback">Open Collaboration and Feedback</a>
          </li>
        </ul>
      </div>

      <div class="tw-p-1 sm:tw-p-2">
        <span class="tw-text-apicuron-purple-100 tw-opacity-75 tw-font-bold tw-text-lg sm:tw-mb-2">Contact</span>
        <ul class="tw-text-sm tw-font-normal">
          <li>
            <svg-icon src="assets/svg/slack-icon.svg"
              svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1 tw-text-apicuron-purple-100 tw-opacity-75"></svg-icon>
            <a href="https://join.slack.com/t/apicuron/shared_invite/zt-1jir4nfu9-gDItsCVg7f0JoY8_mOht7w">Join Slack</a>
          </li>
          <li>
            <svg-icon src="assets/svg/envelope.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a href="mailto:apicuron@ngp-net.bio.unipd.it">E-mail</a>
          </li>
          <li>
            <svg-icon src="assets/svg/bug.svg" svgClass="tw-h-5 tw-w-5 tw-inline tw-pr-1"></svg-icon>
            <a href="https://github.com/BioComputingUP/apicuron-issue-tracker">Issue Tracker</a>
          </li>
        </ul>
      </div>
    </div>

  </div>

</div>


<div class="tw-relative tw-flex tw-flex-row tw-flex-wrap tw-justify-between tw-bg-black tw-gap-2 tw-py-2 tw-items-center">
  <span class="tw-text-xs tw-text-white tw-font-light tw-text-center sm:tw-text-right">
    BioComputing UP - Department of Biomedical Sciences - University of Padua, Italy - 2019. <a
      routerLink="/about">License & disclaimer</a>.
  </span>

  <div class="tw-w-full sm:tw-w-1/4 tw-flex tw-flex-row tw-justify-around tw-items-center">
    <a *ngFor="let item of link_list" target="_blank" [href]="item.link">
      <img class="tw-h-8" [src]="item.image" alt="">
    </a>
  </div>

</div>