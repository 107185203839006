<div
  class="tw-border-2 tw-border-gray-400 tw-bg-white tw-rounded-md tw-p-2 md:tw-p-4 focus:tw-shadow-2xl tw-h-full tw-flex tw-flex-col"
>
  <div class="tw-flex tw-flex-row tw-justify-between">
    <a
      class="tw-text-xl tw-font-bold hover:tw-underline hover:tw-text-apicuron-purple tw-text-gray-800 tw-cursor-pointer"
      [routerLink]="['/databases', resource.resource_id]"
      >{{ resource.resource_name }}
    </a>
    <a
      target="_blank"
      class="tw-text-md tw-text-blue-500 hover:tw-text-blue-600 tw-cursor-pointer hover:tw-underline"
      [href]="resource.resource_url"
      >Visit Resource
      <svg-icon
        src="assets/svg/external-link.svg"
        svgClass="tw-h-3 tw-w-3 tw-inline"
      >
      </svg-icon
    ></a>
  </div>
  <span class="tw-text-gray-400 tw-font-semibold tw-text-left tw-font-dm-sans">
    {{ resource.resource_long_name }}
  </span>
  <p
    class="tw-font-medium tw-text-gray-800 tw-pt-2 md:tw-pt-4 tw-overflow-ellipsis tw-text-justify tw-line-clamp-3"
  >
    {{ description_formatted }}
  </p>
  <div
    class="tw-flex tw-flex-row tw-justify-between tw-pt-2 lg:tw-pt-4 tw-items-end"
  >
    <div class="tw-flex tw-flex-col">
      <span
        class="tw-text-gray-800 tw-font-dm-sans tw-flex tw-flex-col tw-pt-2"
        *ngIf="resource?.stats?.hasLastActivity()"
      >
        <span>
          Contributors:
          {{ resource?.stats?.uniqueContributors }}
        </span>
        <span>
          Contribution Events:
          {{ resource?.stats?.contributionCount }}
        </span>
      </span>
      <span class="tw-block tw-text-gray-800 tw-font-dm-sans">
        <span>
          Joined APICURON on:
          {{ resource?.joined_date | date : "dd-MM-yyyy" : "UTC" }}
        </span>
      </span>
      <span
        class="tw-block tw-text-gray-800 tw-font-dm-sans"
        *ngIf="resource.lastSubmissionDate"
      >
        <span title="Includes curation events, updates to the resource data">
          Last Activity:
          {{ resource.lastSubmissionDate | date : "dd-MM-yyyy" : "UTC" }}
        </span>
      </span>
    </div>
    <div class="tw-self-center">
      <a target="_blank" [href]="resource.resource_url">
        <img
          [src]="resource.logo"
          [alt]="'Logo of ' + resource.resource_name"
          class="tw-h-20 tw-max-h-20"
        />
      </a>
    </div>
  </div>
</div>
