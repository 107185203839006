<!-- This example requires Tailwind CSS v2.0+ -->
<nav
  class="tw-bg-gradient-to-r tw-from-apicuron-purple tw-to-apicuron-red tw-sticky tw-top-0 tw-z-30 tw-shadow-2xl tw-transition-all tw-duration-300"
  [ngClass]="{'md:tw-py-4': !(isCollapsed$ | async) }">
  <!-- <div class="tw-max-w-7xl tw-mx-auto tw-px-2 sm:tw-px-6 lg:tw-px-8"> -->
  <div class="tw-max-w-7xl tw-mx-auto tw-px-2 sm:tw-px-6 2xl:tw-px-0">
    <div class="tw-relative tw-flex tw-items-center tw-justify-between tw-h-16">
      <div class="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center sm:tw-hidden">
        <!-- Mobile menu button-->
        <button aria-controls="mobile-menu" aria-expanded="false"
          class="tw-inline-flex tw-items-center tw-justify-center tw-p-2 tw-rounded-md tw-text-gray-200 hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-white"
          type="button" (click)="closed_mobile_menu = !closed_mobile_menu">
          <span class="tw-sr-only">Open main menu</span>
          <!--
              Icon when menu is closed.
              Menu open: "hidden", Menu closed: "block"
            -->
          <svg-icon [ngClass]="{'tw-hidden': !closed_mobile_menu, 'tw-block': closed_mobile_menu }" src="assets/svg/menu_icon.svg"></svg-icon>
          <!--
              Icon when menu is open.
              Menu open: "block", Menu closed: "hidden"
            -->
            <svg-icon [ngClass]="{'tw-hidden': closed_mobile_menu, 'tw-block': !closed_mobile_menu }" src="assets/svg/close.svg" class="tw-w-6 tw-h-6"></svg-icon>
          <!-- <svg aria-hidden="true" class="tw-hidden tw-h-6 tw-w-6" fill="none" stroke="currentColor" stroke-width="2"
            viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg> -->
        </button>
      </div>
      <div class="tw-flex-1 tw-flex tw-items-center tw-justify-center sm:tw-items-stretch sm:tw-justify-start">
        <div class="tw-flex-shrink-0 tw-flex tw-items-center">
          <a routerLink="/">
            <img alt="APICURON Logo" class="tw-block lg:tw-hidden tw-h-8 tw-w-auto" src="assets/APICURON_white.svg" />
            <img alt="APICURON Logo" class="tw-hidden lg:tw-block tw-h-8 tw-w-auto" src="assets/APICURON_white.svg" />
          </a>
        </div>
      </div>
      <div
        class="tw-absolute tw-inset-y-0 tw-right-0 tw-hidden sm:tw-flex tw-items-center tw-pr-2 sm:tw-static sm:tw-inset-auto sm:tw-ml-6 sm:tw-pr-0">
        <a aria-current="page" class="nav-item tw-relative before:tw-bg-white hover-underline-animation"
          routerLinkActive="active-underline-animation" routerLink="/databases">Databases</a>
        <a class="nav-item tw-relative before:tw-bg-white hover-underline-animation"
          routerLinkActive="active-underline-animation" routerLink="/curators">Curators</a>
        <a class="nav-item tw-relative before:tw-bg-white hover-underline-animation"
          routerLinkActive="active-underline-animation" id="nav_docs" routerLink="/docs">Docs
        </a>
        <a class="nav-item tw-relative before:tw-bg-white hover-underline-animation"
          routerLinkActive="active-underline-animation" id="nav_help" routerLink="/help">Help
        </a>
        <a class="nav-item tw-relative before:tw-bg-white hover-underline-animation"
          routerLinkActive="active-underline-animation" routerLink="/about">About</a>
        
        <auth-dropdown class="tw-dark">
        </auth-dropdown>
      </div>
    </div>
  </div>
  <!-- Mobile menu, show/hide based on menu state. -->
  <div class="sm:tw-hidden" [ngClass]="{'tw-hidden': closed_mobile_menu, 'tw-block':!closed_mobile_menu}"
    id="mobile-menu">
    <div class="tw-px-2 tw-pt-2 tw-pb-3 tw-space-y-1">
      <a aria-current="page" class="tw-block nav-item" routerLink="/databases">Databases</a>
      <a class="tw-block nav-item" routerLink="/curators">Curators</a>
      <a class="tw-block nav-item" href="/docs">Docs</a>
      <a class="tw-block nav-item" routerLink="/help">Help</a>
      <a class="tw-block nav-item" routerLink="/about">About</a>

      <auth-dropdown class="tw-dark">

      </auth-dropdown>


    </div>
  </div>
</nav>